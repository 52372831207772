/* eslint-disable */
import instrumentsData from '@components/watchlist/instruments.json';

class Search {
  maxResults: number;
  lastResults: DetailedInstrument[];
  currentYear: number;
  months: string[];
  weeklyMonthsMap: Record<string, string>;
  strikePrecision: Record<string, number>;
  eventsInstruments: Record<string, boolean>;
  defaultTickSize: Record<string, number>;
  segments: Set<string>;
  segmentsList: string[];
  equitySegments: Set<string>;
  optionsSegments: Set<string>;
  futuresSegments: Set<string>;
  exchangeSegments: Set<string>;
  tradeableSegments: Set<string>;
  segmentsID: Record<string, number>;
  segmentsAliases: Record<string, string[]>;
  segmentsExchangeMap: Record<string, string>;
  instrumentsMap: Record<string, Record<string, SymbolData>>;
  instrumentsArray: Record<string, SymbolData[]>;
  equitySymbolMap: Record<string, [string, string][]>;
  isinMap: Record<string, string[]>;
  tokenMap: Record<number, string>;
  regWeeklyExpiry: RegExp;
  regSymbol: RegExp;

  constructor() {
    this.regSymbol = RegExp(
      /(.+?)((-EQ)|([0-9]{2})(([A-Z]{3})|(([A-Z]{3})([0-9]{2}))|(([0-9OND])([0-9]{2})))(F|(C|P)([0-9.]+)(W.)?))/i
    );
    this.regWeeklyExpiry = RegExp(
      /([0-9]{2})(([A-Z]{3})|(([0-9OND])([0-9]{2})))/i
    );
    this.maxResults = 25;
    this.lastResults = [];
    this.currentYear = new Date().getFullYear();
    this.strikePrecision = {};
    this.eventsInstruments = {};
    this.months = [];
    this.weeklyMonthsMap = {};
    this.segmentsID = {};
    this.segmentsAliases = {};
    this.equitySymbolMap = {};
    this.isinMap = {};
    this.instrumentsArray = {};
    this.segmentsExchangeMap = {};
    this.tradeableSegments = new Set();
    this.exchangeSegments = new Set();
    this.futuresSegments = new Set();
    this.optionsSegments = new Set();
    this.equitySegments = new Set();
    this.segmentsList = [];
    this.segments = new Set();
    this.isinMap = {};
    this.instrumentsMap = {};
    this.defaultTickSize = {
      "MCX-OPT": 1,
      "MCX-FUT": 1,
      MCX: 1,
    };
    this.tokenMap = {};
  }
  init(e: {
    months: string[];
    weekly_months: Record<number | string, string>;
    segments: string[];
    equity_segments: string[];
    options_segments: string[];
    futures_segments: string[];
    exchange_segments: string[];
    tradeable_segments: string[];
    segments_id_map: Record<string, number>;
    segments_aliases: Record<string, string[]>;
    segments_exchange_map: Record<string, string>;
    events?: string[];
    instruments: Record<string, Instrument[]>;
  }) {
    this.months = e.months;
    this.weeklyMonthsMap = e.weekly_months;
    this.segments = new Set(e.segments);
    this.segmentsList = e.segments;
    this.equitySegments = new Set(e.equity_segments);
    this.optionsSegments = new Set(e.options_segments);
    this.futuresSegments = new Set(e.futures_segments);
    this.exchangeSegments = new Set(e.exchange_segments);
    this.tradeableSegments = new Set(e.tradeable_segments);
    this.segmentsID = e.segments_id_map;
    this.segmentsAliases = e.segments_aliases;
    this.segmentsExchangeMap = e.segments_exchange_map;
    this.eventsInstruments = e.events
      ? Object.assign({}, this.arrayToMap(e.events))
      : {};
    this.instrumentsArray = {};
    this.tokenMap = {};

    this.equitySymbolMap = {};
    for (let es of e.equity_segments)
      this.buildEquitySymbolMap(es, <EquityInstrument[]>e.instruments[es]);

    this.instrumentsMap = {};
    for (let s of e.segments)
      e.instruments[s] && this.feed(s, e.instruments[s]);

    const segments = Object.keys(this.instrumentsMap);
    for (let i = 0; i < segments.length; i += 1) {
      // loop through all segments
      const instruments = this.instrumentsMap[segments[i]];

      // assign all tokens to tokenMap
      const instrumentKeys = Object.keys(instruments);
      for (let j = 0; j < instrumentKeys.length; j += 1) {
        this.tokenMap[instruments[instrumentKeys[j]][0]] = instrumentKeys[j];
      }
    }
  }
  
  /**
   * Builds the equity symbol map for the given segment and instruments.
   * 
   * The equity symbol map is a dictionary where the keys are the ISINs and
   * the values are arrays of [segment, ticker] pairs.
   * 
   * @param segment - The segment the instruments belong to.
   * @param instruments - The list of equity instruments.
   */
  buildEquitySymbolMap(segment: string, instruments: EquityInstrument[]) {
    for (let instrument of instruments) {
      let id = instrument[5] || instrument[1];
      // Create the array of [segment, ticker] pairs if it doesn't exist
      this.equitySymbolMap[id] || (this.equitySymbolMap[id] = []);
      // Add the [segment, ticker] pair to the array
      this.equitySymbolMap[id].push([segment, instrument[1]]);
    }
  }

  /**
   * Loads the given instruments into the data store.
   * 
   * @param segment - The segment the instruments belong to.
   * @param instruments - The list of instruments.
   */
  feed(segment: string, instruments: Instrument[]) {
    this.instrumentsMap[segment] = {};
    this.instrumentsArray[segment] = [];

    // Load the instruments into the data store based on the segment type
    if (this.equitySegments.has(segment)) {
      // Load equity instruments
      this.loadEquity(segment, <EquityInstrument[]>instruments, "");
    } else if ("INDICES" === segment) {
      // Load indices instruments
      (<IndexInstrument[]>instruments).forEach((instrument) => {
        this.loadEquity(segment, instrument[1], instrument[0]);
      });
    } else if (this.optionsSegments.has(segment)) {
      // Load options instruments
      this.loadOptions(segment, <OptionsInstrument[]>instruments, segment);
    } else if (this.futuresSegments.has(segment)) {
      // Load futures instruments
      this.loadFutures(segment, <FuturesInstrument[]>instruments, segment);
    } else {
      // If the segment is not recognized, print a log message
      console.log("skip loading segment: ", segment);
    }
  }

  /**
   * Performs a search for user input and returns Instruments matching the search.
   * Number of items returned is 25 by default.
   * 
   * @param userInput - The user's input string
   * @param maxResults - The maximum number of results to return
   * @returns An array of DetailedInstrument objects
   */
  search(userInput: string, maxResults?: number): DetailedInstrument[] {
    maxResults || (maxResults = this.maxResults);
    let tokens = this.tokenize(userInput),
      segmentMatches = this.searchSegments(tokens),
      uncommonTokens = this.subtract(tokens, segmentMatches.matchedTokens),
      rankedSegments = this.rankSegments(segmentMatches.results);
    // if no segments matched, use all segments
    0 === rankedSegments.length && (rankedSegments = this.allSegements());
    let detailedInstrument = this.searchSymbols(rankedSegments, uncommonTokens, userInput, maxResults);
    if (detailedInstrument.length > 0) this.lastResults = detailedInstrument;
    // if no results found and there are previous results, return them
    else if (this.lastResults) return this.lastResults;
    return detailedInstrument;
  }

  /**
   * Returns a DetailedInstrument object using the provided parameters.
   * 
   * The segment or exchange parameter is mandatory. If both are provided,
   * the segment is used.
   * 
   * If the ticker is found in the instruments map, a DetailedInstrument object
   * is created with the instrument details.
   * 
   * If the ticker is not found, a dummy DetailedInstrument object is returned
   * with default values.
   * 
   * @param {string} ticker - The ticker symbol to search for.
   * @param {string} [segment] - The segment to search in.
   * @param {string} [exchange] - The exchange to search in.
   * @returns {DetailedInstrument} - The DetailedInstrument object.
   */
  get(ticker: string, segment?: string, exchange?: string) {
    if (
      ((ticker = ticker.toUpperCase()),
      // strip "-EQ" from ticker if present
      -1 !== ticker.indexOf("-EQ") && (ticker = ticker.split("-EQ")[0]),
      exchange && !segment && (segment = this.getSegment(ticker, exchange)),
      // if no exchange is provided, use the segment
      !exchange && segment && (exchange = this.getExchange(segment)),
      ticker && segment && this.instrumentsMap[segment])
    ) {
      let instrument = this.instrumentsMap[segment!][ticker];
      // instrument found
      if (instrument) {
        let e = this.makeInstrument({
          exchangeToken: instrument[0],
          tradingSymbol: instrument[1],
          segment: segment!,
          exchange: this.segmentsExchangeMap[segment!],
          segment2: instrument[7] || "",
          tickSize: instrument[2],
          lotSize: instrument[3],
          company: instrument[4] || "",
          isin: instrument[5] || "",
          isWeekly: instrument[6],
        });
        return (e.isFound = !0), e;
      }
    }

    // returning dummy instrument
    let r = this.makeInstrument({
      exchangeToken: 0,
      tradingSymbol: ticker,
      segment: (segment || exchange)!,
      exchange: (exchange || segment)!,
      segment2: (segment || exchange)!,
      tickSize: this.defaultTickSize[(exchange || segment)!] || 0.05,
      lotSize: 1,
      company: "",
      isin: "",
      isWeekly: false,
    });
    return (r.isFound = !1), r;
  }

  /**
   * Determines the segment for a given ticker and exchange segment.
   * 
   * @param {string} ticker - The ticker symbol.
   * @param {string} exchangeSegment - The exchange segment.
   * @returns {string | undefined} - The segment for the given ticker and exchange segment.
   */
  getSegment(ticker: string, exchangeSegment: string): string | undefined {
    // Check if the exchange segment is already known
    if (this.exchangeSegments.has(exchangeSegment)) {
      return exchangeSegment;
    }

    // Execute the regular expression to parse the ticker
    let s = this.regSymbol.exec(ticker);

    // Determine the segment based on the regex result
    if (s) {
      if ("F" === s[13]) {
        return exchangeSegment + "-FUT"; // Future segment
      } else if (s[14] && s[15]) {
        return exchangeSegment + "-OPT"; // Option segment
      }
    }

    // Return undefined if no segment is determined
    return undefined;
  }

  /**
   * Returns the exchange for a given segment.
   * @param {string} segment - The segment to get the exchange for.
   * @returns {string} The exchange for the given segment.
   */
  getExchange(segment: string): string {
    return segment.split("-")[0];
  }

  /**
   * Populates class properties 'instrumentsArray' & 'instrumentsMap'
   * for EQUITY data recieved from instruments.json file
   * 
   * @param {string} segment - The segment to which the equity instruments belong.
   * @param {EquityInstrument[]} instruments - The list of equity instruments.
   * @param {string} segment2 - An additional segment identifier.
   */
  loadEquity(
    segment: string,
    instruments: EquityInstrument[],
    segment2: string
  ) {
    let prevToken = -1, // previous token value
      prevTickSize = -1, // previous tick size value
      prevLotSize = -1; // previous lot size value

    // looping through the instruments
    for (let instrument of instruments) {
      let token, // current token
        tickSize, // current tick size
        lotSize, // current lot size
        ticker = instrument[1], // instrument ticker
        fullName = instrument[2]; // instrument full name

      // assign token
      token = -1 === prevToken ? instrument[0] : prevToken + instrument[0];
      prevToken = token;

      // assign tickSize
      instrument[3]
        ? ((tickSize = instrument[3]), (prevTickSize = tickSize))
        : (tickSize = prevTickSize);

      // assign lotSize
      instrument[4]
        ? ((lotSize = instrument[4]), (prevLotSize = lotSize))
        : (lotSize = prevLotSize);

      // preparing symbol data
      let symData: SymbolData = [
        token, // token
        ticker, // ticker
        tickSize, // tick size
        lotSize, // lot size
        fullName, // full name
        instrument[5], // ISIN
        false, // isWeekly
        segment2, // additional segment
      ];

      // appending to datasets
      this.instrumentsArray[segment].push(symData);
      this.instrumentsMap[segment][ticker] = symData;
    }
  }

  /**
   * Populates class properties 'instrumentsArray' & 'instrumentsMap'
   * for OPTIONS data recieved from instruments.json file
   * 
   * @param {string} segment - The segment to which the options belong.
   * @param {OptionsInstrument[]} instruments - The list of options instruments.
   * @param {string} segment2 - An additional segment identifier.
   */
  loadOptions(
    segment: string,
    instruments: OptionsInstrument[],
    segment2: string
  ) {
    // datasets
    let symDataList: SymbolData[] = [];
    let symbolToData: Record<string, SymbolData> = {};

    // looping through the instruments
    for (let a = 0; a < instruments.length; a++) {
      let instrument = instruments[a];
      let ticker = instrument[0]; // ticker
      let tickSize = instrument[1]; // tickSize
      let lotSize = instrument[2]; // lot size

      // looping through all expiries for this instrument
      for (let t = 0; t < instrument[3].length; t++) {
        let expiry = instrument[3][t][0]; // expiry
        let cePeSet = instrument[3][t][1]; // CE/PE data set

        // looping through CE/PE dataset keys.
        // As of today there would be only two keys: CE or PE
        for (let optType in cePeSet)
          if (cePeSet.hasOwnProperty(optType)) {
            let dataSet = cePeSet[optType]; // CE/PE dataset
            let tokenPrev = -1;
            let strikePrev = -1;

            // looping through CE/PE dataset
            for (let l = 0; l < dataSet.length; l++) {
              let strike: number;
              let token: number;
              let lot: number;

              // calculating token and strike price
              -1 === tokenPrev && -1 === strikePrev
                ? ((token = dataSet[l][0]), (strike = +dataSet[l][1]))
                : ((token = tokenPrev + dataSet[l][0]),
                  (strike =
                    Math.round(
                      strikePrev * Math.pow(10, 9) +
                        +dataSet[l][1] * Math.pow(10, 9)
                    ) / Math.pow(10, 9)));
              strikePrev = strike;
              tokenPrev = token;

              // lot size
              lot = dataSet[l][2] || lotSize;

              // Preparing symbol
              let symbol = "";
              symbol = this.strikePrecision[segment]
                ? ticker +
                  this.formatToDDMONYY(expiry) +
                  optType.substring(0, 1) +
                  parseFloat(strike + "").toFixed(this.strikePrecision[segment])
                : ticker +
                  this.formatToDDMONYY(expiry) +
                  optType.substring(0, 1) +
                  strike;

              // preparing expiry
              let expandedExpiry = this.expandExpiryString(expiry);
              let S = expandedExpiry !== expiry ? expandedExpiry : undefined;

              // preparing symbol data
              let symData: SymbolData = [
                token,
                symbol,
                tickSize,
                lot,
                S,
                undefined,
                expiry.endsWith("W"), // isWeekly
                segment2,
              ];

              // appending to datasets
              symDataList.push(symData);
              symbolToData[symData[1]] = symData;
            }
          }
      }
    }

    // class properties assignment
    this.instrumentsArray[segment] = symDataList;
    this.instrumentsMap[segment] = symbolToData;
  }

  /** 
   * Populates class properties 'instrumentsArray' & 'instrumentsMap'
   * for FUTURES data received from instruments.json file.
   * 
   * @param {string} segment - The segment to which the futures belong.
   * @param {FuturesInstrument[]} instruments - The list of futures instruments.
   * @param {string} segment2 - An additional segment identifier.
   */
  loadFutures(
    segment: string,
    instruments: FuturesInstrument[],
    segment2: string
  ) {
    let symDataList: SymbolData[] = [];
    let symbolToData: Record<string, SymbolData> = {};

    // Looping through the instruments
    for (let a = 0; a < instruments.length; a++) {
      let ticker = instruments[a][0]; // Ticker for the instrument
      let prevToken = -1; // Initialize previous token
      let tickSize = instruments[a][1]; // Tick size for the instrument
      let lot = instruments[a][2]; // Default lot size

      // Looping through all expiries for this instrument
      for (let u = 0; u < instruments[a][3].length; u++) {
        let expiry: string; // Expiry date
        let lotSize: number; // Lot size for the expiry
        let token: number; // Token for the expiry

        // Calculating token
        token =
          -1 === prevToken
            ? instruments[a][3][u][0]
            : prevToken + instruments[a][3][u][0];
        prevToken = token;

        // Expiry date
        expiry = instruments[a][3][u][1];

        // Lot size
        lotSize = instruments[a][3][u][2] || lot;

        // Expand expiry to full format
        let expandedExpiry = this.expandExpiryString(expiry);
        let S = expandedExpiry !== expiry ? expandedExpiry : undefined;

        // Preparing symbol data
        let symData: SymbolData = [
          token,
          ticker + this.formatToDDMONYY(expiry) + "F", // Formatted symbol
          tickSize,
          lotSize,
          S,
          undefined,
          expiry.endsWith("W"), // isWeekly flag
          segment2,
        ];

        // Appending to datasets
        symDataList.push(symData);
        symbolToData[symData[1]] = symData;
      }
    }

    // Assign class properties
    this.instrumentsArray[segment] = symDataList;
    this.instrumentsMap[segment] = symbolToData;
  }

  /**
   * Format the expiry string in DDMONYY format if it is passed
   * as DDMYY or DDMYYW format. Otherwise, return the exact string.
   * @param expiryString The expiry string to format.
   * @returns The formatted string in DDMONYY format.
   */
  formatToDDMONYY(expiryString: string): string {
    // Remove the 'W' from the end of the string if it exists
    expiryString = expiryString.endsWith("W") ? expiryString.slice(0, expiryString.length - 1) : expiryString;

    // Use a regex to extract the day, month, and year from the expiry string
    const matchResult = this.regWeeklyExpiry.exec(expiryString);
    // If the regex matches and the day, month are present
    if (matchResult && matchResult[1] && matchResult[5]) {
      // Convert the month letter to a number
      const month =
        matchResult[5] === "O" ? 10 : matchResult[5] === "N" ? 11 : matchResult[5] === "D" ? 12 : +matchResult[5];

      // Return the formatted string in DDMONYY format
      return (
        matchResult[1] + // day
        this.months[month - 1] + // month
        matchResult[6] // year
      );
    }
    
    return expiryString;
  }

  /**
   * Searches for symbols that match the user's input within the specified segments.
   * 
   * @param segments - The list of segments to search within.
   * @param tokens - The tokenized user input for searching.
   * @param userInput - The original user input string.
   * @param maxResults - The maximum number of results to return.
   * @returns An array of formatted detailed instruments.
   */
  searchSymbols(
    segments: string[],
    tokens: string[],
    userInput: string,
    maxResults: number
  ) {
    let instruments: [string, SymbolData, number, number][] = [];

    // Iterate over each segment in the segment list
    for (let segment of this.segmentsList) {
      // Skip if the instruments array for the segment is undefined
      if (!this.instrumentsArray[segment]) continue;

      // Determine if the current segment is in the provided segments list
      let isSegmentMatched = segments.includes(segment);

      // Iterate over each instrument in the segment's instruments array
      for (let index = 0; index < this.instrumentsArray[segment].length; index++) {
        let relevanceScore = 0, isMatch = true;

        // Check if the instrument's ticker matches the user input
        if (this.instrumentsArray[segment][index][1] === userInput.toUpperCase()) {
          relevanceScore = -100;
        } else if (isSegmentMatched) {
          // Check the tokenized input against the instrument's ticker and additional data
          for (let token of tokens) {
            let tickerMatchIndex = this.instrumentsArray[segment][index][1].indexOf(token);
            let descriptionMatchIndex = this.instrumentsArray[segment][index][4]?.indexOf(token) ?? -1;

            // If neither ticker nor description contains the token, mark as non-match
            if (tickerMatchIndex === -1 && descriptionMatchIndex === -1) {
              isMatch = false;
              break;
            }

            // Adjust relevance score based on match position
            if (tickerMatchIndex === 0) {
              relevanceScore -= 2;
            } else if (descriptionMatchIndex >= 0) {
              relevanceScore -= 1;
            }
          }
        } else {
          isMatch = false;
        }

        // If a match is found, add the instrument to the results
        if (isMatch) {
          instruments.push([segment, this.instrumentsArray[segment][index], relevanceScore, instruments.length]);
        }
      }
    }

    // Format and return the top results based on relevance
    return this.formatResults(instruments, maxResults);
  }

  /**
   * Sorts the search results by their relevance and returns the top
   * {@link maxResults} results.
   * @param {Array} instruments - An array of tuples containing the segment, instrument data, relevance score, and index.
   * @param {number} maxResults - The maximum number of results to return.
   * @returns {Array} An array of {@link DetailedInstrument} objects.
   */
  formatResults(instruments: [string, SymbolData, number, number][], maxResults: number): DetailedInstrument[] {
    instruments.sort(function (a, b) {
      // Sort by relevance score and then by index
      return a[2] === b[2] ? a[3] - b[3] : a[2] < b[2] ? -1 : 1;
    });
    instruments = instruments.slice(0, maxResults);
    let detailedInstruments: DetailedInstrument[] = [];
    for (let i = 0; i < instruments.length; i++) {
      let instrumentData = instruments[i][1],
        segment = instruments[i][0];
      detailedInstruments.push(
        this.makeInstrument({
          exchangeToken: instrumentData[0],
          tradingSymbol: instrumentData[1],
          segment: segment,
          exchange: this.segmentsExchangeMap[segment],
          segment2: instrumentData[7],
          tickSize: instrumentData[2],
          lotSize: instrumentData[3],
          company: instrumentData[4] || "",
          isin: instrumentData[5] || "",
          isWeekly: instrumentData[6],
        })
      );
    }
    return detailedInstruments;
  }

  /**
   * Returns an array of all the segments that are available in the datafeed.
   * @returns {string[]} An array of all the segments.
   */
  allSegements(): string[] {
    let segments: string[] = [];
    // Loop through all the segments and push them to the array
    this.segments.forEach((t) => segments.push(t));
    return segments;
  }

  /**
   * Rank segments by their match count in descending order.
   * @param segmentsToMatchCount {Record<string, number>} A map of segment names to their match count.
   * @returns {string[]} An array of segment names ranked by their match count.
   */
  rankSegments(segmentsToMatchCount: Record<string, number>) {
    // Initialize the segments and match counts arrays
    const segments: string[] = [],
      matchCounts: number[] = [];

    // Loop through the segments and add them to the arrays
    for (const segment in segmentsToMatchCount) {
      segmentsToMatchCount.hasOwnProperty(segment) && (segments.push(segment), matchCounts.push(segmentsToMatchCount[segment]));
    }

    // Initialize the ranked segments array and the highest match count
    let rankedSegments: string[] = [],
      idx = -1,
      highestMatchCount = -1;

    // Loop until all segments have been ranked
    while (true) {
      // Find the index of the highest match count in the match counts array
      if (((idx = matchCounts.indexOf(Math.max.apply(Math, matchCounts))), -1 === idx)) break;

      // Get the current match count and set the match count at the found index to 0
      const currentMatchCount = matchCounts[idx];
      if (((matchCounts[idx] = 0), currentMatchCount < highestMatchCount)) break;

      // Add the segment at the found index to the ranked segments array
      (highestMatchCount = currentMatchCount), rankedSegments.push(segments[idx]);
    }

    // Return the ranked segments array
    return rankedSegments;
  }

  /**
   * Checks whether strArr contains a string that compares true to any
   * segment alias. If yes, that string is append to 'matchedTokens' property of response.
   * Also all possible segments corresponding to that segment alias are appended to 'results'
   * property of the response.
   * @param strArr {string[]} An array of strings to be searched for in the segment aliases
   * @returns {{results: Record<string, number>, matchedTokens: string[]}} response Object
   */
  searchSegments(strArr: string[]): { results: Record<string, number>, matchedTokens: string[] } {
    let results: Record<string, number> = {};
    let tokens: string[] = [];
    // loop through all the segments
    for (let segment of this.segmentsList)
      // loop through all the strings in the input array
      for (let i = 0; i < strArr.length; i++)
        // check if the string matches any of the segment aliases
        -1 !== this.segmentsAliases[segment].indexOf(strArr[i]) &&
          // if yes, add that string to the matched tokens array
          (tokens.push(strArr[i]),
          // add the segment to the results object with the count of matches
          results.hasOwnProperty(segment) || (results[segment] = 0),
          results[segment]++);
    // return the response object
    return {
      results: results,
      matchedTokens: this.unique(tokens),
    };
  }

  /**
   * Trims & returns an array containing only the unique tokens extracted from the input string
   *
   * @param userInput {string} input string to be tokenized
   * @returns {string[]} array of unique tokens
   */
  tokenize(userInput: string): string[] {
    userInput = this.trim(userInput).toUpperCase();
    // remove all non-alphanumeric characters, except for '.' and '&'
    userInput = this.trim(userInput.replace(/[^a-z0-9.\s&]/gi, " "));
    // remove all whitespace characters
    userInput = userInput.replace(/[\s+]/gi, " ");
    // split the string into an array of tokens
    let tokens = userInput.split(" ");
    // return the unique tokens
    return this.unique(tokens);
  }

  /** Removes leading and trailing whitespace from a given string*/
  trim(userInput: string) {
    return "undefined" === typeof String.prototype.trim
      ? userInput.replace(/^\s+|\s+$/gm, "")
      : userInput.trim();
  }

  /**
  Takes an array of strings, arr, as input and
  returns a new array containing only the unique elements from 'arr'
  */
  unique(arr: string[]) {
    return [...new Set(arr.sort())];
  }

  /**
  Takes two arrays of strings, a1 and a2, and returns a new array containing
  elements that are present in a1 but not in a2
  */
  subtract(a1: string[], a2: string[]) {
    return a1.filter((item) => !a2.includes(item));
  }

  /**
   * Prepares a detailed instrument object with the given parameters.
   * 
   * @param {MakeInstrumentsParams} params - The parameters for creating the instrument.
   * @returns {DetailedInstrument} - The detailed instrument object.
   */
  makeInstrument({
    exchangeToken,
    tradingSymbol,
    segment,
    exchange,
    segment2,
    tickSize,
    lotSize,
    company,
    isin,
    ignoreRelated,
    isWeekly,
  }: MakeInstrumentsParams): DetailedInstrument {
    // Parse the trading symbol to initialize the instrument
    let instrument = this.parse(tradingSymbol, exchange);

    // Set instrument properties
    instrument.segment = segment;
    instrument.exchange = exchange;
    instrument.segment2 = segment2;
    instrument.tickSize = tickSize;
    instrument.lotSize = lotSize;
    instrument.company = company;
    instrument.tradable = this.tradeableSegments.has(segment);
    instrument.precision = exchange === "CDS" || exchange === "BCD" ? 4 : 2;
    instrument.fullName = this.getFullName(instrument);
    instrument.isWeekly = isWeekly;
    instrument.niceName = this.getNiceName(instrument);
    instrument.stockWidget = this.isStockWidget(instrument);
    instrument.exchangeToken = exchangeToken;
    instrument.instrumentToken = exchangeToken;
    instrument.isin = isin;
    instrument.related = [];
    instrument.underlying = this.getUnderlyingInstrument(instrument);
    instrument.auctionNumber = undefined;

    // Populate related instruments if not ignored
    if (!ignoreRelated) {
      let instrumentList = this.equitySymbolMap[isin || tradingSymbol];
      if (instrumentList) {
        for (let instrumentData of instrumentList) {
          if (instrumentData[0] !== segment) {
            let instrumentInfo = this.instrumentsMap[instrumentData[0]][instrumentData[1]];
            if (!instrumentInfo) break;
            instrument.related.push(
              this.makeInstrument({
                exchangeToken: instrumentInfo[0],
                tradingSymbol: instrumentInfo[1],
                segment: instrumentData[0],
                exchange: this.segmentsExchangeMap[instrumentData[0]],
                segment2: segment2,
                tickSize: instrumentInfo[2],
                lotSize: instrumentInfo[3],
                company: instrumentInfo[4] || "",
                isin: instrumentInfo[5] || "",
                ignoreRelated: true,
                isWeekly: instrumentInfo[6],
              })
            );
          }
        }
      }
    }

    // Set company name if not provided and related instruments have a company
    if (!company && instrument.related.length > 0) {
      for (let relatedInstrument of instrument.related) {
        if (relatedInstrument.company) {
          instrument.company = relatedInstrument.company;
          break;
        }
      }
    }

    // Determine if the instrument is an event
    instrument.isEvent =
      (exchange === "NSE" || exchange === "BSE" || exchange === "INDICES") &&
      this.eventsInstruments[tradingSymbol];

    return instrument;
  }

  /**
   * Returns the underlying instrument of the given instrument. The underlying
   * instrument is the base instrument of the given instrument, if the given
   * instrument is a derivative. If the given instrument is not a derivative,
   * this method returns undefined.
   *
   * @param {DetailedInstrument} instrument - The instrument to get the
   * underlying instrument of.
   * @returns {DetailedInstrument | undefined} - The underlying instrument of
   * the given instrument, or undefined if the given instrument is not a
   * derivative.
   */
  getUnderlyingInstrument(instrument: DetailedInstrument) {
    if (
      instrument &&
      !this.IsEquity(instrument.exchange) &&
      instrument.symbol
    ) {
      let symbol = instrument.symbol,
        xchng = instrument.exchange;
      // If the exchange is NFO, get the underlying instrument from NSE
      if ("NFO" === instrument.exchange) xchng = "NSE";
      // If the exchange is BFO, get the underlying instrument from BSE
      else {
        if ("BFO" !== instrument.exchange) return undefined;
        xchng = "BSE";
      }
      // Handle the special cases of BANKNIFTY, NIFTY, FINNIFTY and MIDCPNIFTY
      if ("BANKNIFTY" === symbol)
        ((symbol = "NIFTY BANK"), (xchng = "INDICES"));
      else if ("NIFTY" === symbol)
        ((symbol = "NIFTY 50"), (xchng = "INDICES"));
      else if ("FINNIFTY" === symbol)
        ((symbol = "NIFTY FIN SERVICE"), (xchng = "INDICES"));
      else if ("MIDCPNIFTY" === symbol)
        ((symbol = "NIFTY MID SELECT"), (xchng = "INDICES"));
      // Get the underlying instrument
      let detailedInstrument = this.get(symbol, undefined, xchng);
      // Return the underlying instrument if it exists
      return detailedInstrument && detailedInstrument.exchangeToken ? detailedInstrument : undefined;
    }
    // Return undefined if the given instrument is not a derivative
    return undefined;
  }

  /**
   * Returns the full name of the instrument. The full name is the same as the
   * trading symbol, unless the exchange is BSE, in which case the full name is
   * the symbol followed by the trading symbol in parentheses.
   *
   * @param {DetailedInstrument} instrument - The instrument to get the full name
   * of.
   * @returns {string} - The full name of the instrument.
   */
  getFullName(instrument: DetailedInstrument): string {
    return "BSE" === instrument.exchange
      ? instrument.symbol + " (" + instrument.tradingSymbol + ")"
      : instrument.tradingSymbol;
  }

  /**
   * Returns a nice, readable, space-separated representation of the instrument.
   * 
   * @param {DetailedInstrument} instrument - The instrument to format.
   * @returns {string} - The formatted instrument name.
   */
  getNiceName(instrument: DetailedInstrument): string {
    // If the instrument type is equity, return the trading symbol directly
    if (instrument.type === "EQ") {
      return instrument.tradingSymbol;
    }

    let symbol = instrument.symbol;

    // Append expiry day to the symbol if it exists
    if (instrument.expiryDay) {
      symbol += ` ${instrument.expiryDay + this.dateSuffix(instrument.expiryDay)}`;
    }

    // Append expiry month and year to the symbol if they exist
    if (instrument.expiryMonth && instrument.expiryYear) {
      symbol += ` ${this.months[instrument.expiryMonth - 1]} ${instrument.expiryYear.toString().substring(2, 4)}`;
    }

    // For options, append the strike price and option type
    if (instrument.type === "OPT") {
      symbol += ` ${instrument.strike} ${instrument.optionType}`;
    } 
    // For futures, append the type
    else if (instrument.type === "FUT") {
      symbol += ` ${instrument.type}`;
    }

    return symbol;
  }

  /**
   * Determines if the given instrument is a stock widget.
   * 
   * @param {DetailedInstrument} instrument - The instrument to check.
   * @returns {boolean} - True if the instrument belongs to an equity segment, false otherwise.
   */
  isStockWidget(instrument: DetailedInstrument): boolean {
    // Check if the instrument's exchange is part of the equity segments
    return this.equitySegments.has(instrument.exchange);
  }

  /**
   * Checks whether the given segment is an equity segment (Indices will also be considered as Equity).
   * 
   * @param {string} segment - The segment to check.
   * @returns {boolean} - True if the segment is an equity segment, false otherwise.
   */
  IsEquity(segment: string) {
    return this.equitySegments.has(segment) || "INDICES" === segment;
  }

  /**
   * Extracts the equity symbol from a given string.
   * If the string has a hyphen ('-') at the third position from the end,
   * it removes the last three characters and returns the remaining string.
   * Otherwise, it returns the original string.
   * 
   * @param {string} symbol - The input string to extract the equity symbol from.
   * @returns {string} - The extracted equity symbol.
   */
  extractEqSymbol(symbol: string): string {
    // Check if the string length is greater than 3 and the third last character is a hyphen
    return symbol.length > 3 && symbol[symbol.length - 3] === '-' ? symbol.slice(0, -3) : symbol;
  }

  /**
   * Parses the trading symbol to extract the scrip code and other information.
   * 
   * @param tradingSymbol The trading symbol to parse.
   * @param exchange The exchange of the trading symbol.
   * 
   * @returns The parsed instrument with the scrip code and other information.
   */
  parse(tradingSymbol: string, exchange: string): DetailedInstrument {
    let scripCode = "";

    // extracting scrip code if contained in BSE symbol
    if (-1 !== tradingSymbol.indexOf("|") && "BSE" === exchange) {
      var tradingSymbolSplits = tradingSymbol.split("|");
      (tradingSymbol = tradingSymbolSplits[0]), (scripCode = tradingSymbolSplits[1]);
    }

    // instrument struct
    let instrument = <DetailedInstrument>{};
    instrument.tradingSymbol = tradingSymbol;
    instrument.scripCode = scripCode;

    // regex search
    let regexResult = this.regSymbol.exec(tradingSymbol);

    // no result
    if (!regexResult) {
      instrument.type = "EQ";
      instrument.symbol = this.extractEqSymbol(tradingSymbol);
      return instrument;
    }

    instrument.symbol = regexResult[1];

    // if equity
    if ("-EQ" === regexResult[2]) {
      instrument.type = "EQ";
    } else {
      // Figuring whether future or Option
      if ("F" === regexResult[13]) { // eg. F
        instrument.type = "FUT";
      } else if (regexResult[14] && regexResult[15]) { // eg C && 36000
        
        instrument.type = "OPT";
        instrument.optionType = regexResult[14] === "C" ? "CE" : "PE";
        instrument.strike = parseFloat(regexResult[15]);
      }
    }

    // If the symbol contains the expiry date
    if (regexResult[4] && regexResult[8] && regexResult[9]) { // DD && MON (month) && YY
      // Possible if symbol format : BANKNIFTY05JUL24F or BANKNIFTY05JUL24C32500

      instrument.expiryMonth = this.months.indexOf(regexResult[8]) + 1;
      instrument.expiryYear = parseInt(regexResult[9]) + 2e3;
      instrument.expiryDay = parseInt(regexResult[4]);
      instrument.expiryWeek = Math.floor(parseInt(regexResult[4]) / 7) + 1;
    } else {
      // possible if symbol format : BANKNIFTY24MAYF

      if (regexResult[4] && regexResult[5]) { //  YY && MON (month)
        instrument.expiryYear = parseInt(regexResult[4]) + 2e3;
        instrument.expiryMonth =
          this.months.indexOf(regexResult[5].toUpperCase()) + 1;
      }
    }

    return instrument;
  }

  /**
   * Returns the expanded version of the expiry string.
   * 
   * The expiry string can be in one of the following formats:
   *  - DDMYY (e.g. "22524")
   *  - DDMYYW (e.g. "22524W")
   *  - DDMMM (e.g. "24MAY")
   * 
   * If the expiry string is in the format DDMYY or DDMYYW, this function
   * returns a string in the format "DD MMM" or "DD MMM WEEKLY" respectively.
   * If the expiry string is not in one of the above formats, this function
   * returns the expiry string as is.
   * 
   * eg. for passed expiry: 22524W, returns  22 MAY WEEKLY.
   * eg. for passed expiry: 22524, returns  22 MAY.
   * 
   * @param expiry - The expiry string.
   * @returns The expanded expiry string.
   */
  expandExpiryString(expiry: string): string {
    // is weekly
    let isWeekly = false;
    expiry[expiry.length - 1] === "W" &&
      ((isWeekly = true), (expiry = expiry.slice(0, expiry.length - 1)));

    // parse Regex
    const regexTokens = this.regWeeklyExpiry.exec(expiry);

    // Expiry format is DDMYY
    if (regexTokens && regexTokens[1] && regexTokens[5]) {
      // weekly
      if (isWeekly) {
        return regexTokens[1] + " " + this.weeklyMonthsMap[regexTokens[5]] + " WEEKLY";
      }
      return regexTokens[1] + " " + this.weeklyMonthsMap[regexTokens[5]];
    }

    // probably expiry format is: 24MAY
    return expiry;
  }

  /**
   * Returns the appropriate ordinal suffix for a given day of the month.
   * 
   * @param day - The day of the month as a number.
   * @returns The ordinal suffix ("st", "nd", "rd", or "th") for the given day.
   */
  dateSuffix(day: number): string {
    // For days 4-20, the suffix is always "th"
    if (day > 3 && day < 21) return "th";

    // Determine suffix based on the last digit of the day
    switch (day % 10) {
      case 1:
        return "st"; // e.g., 1st, 21st
      case 2:
        return "nd"; // e.g., 2nd, 22nd
      case 3:
        return "rd"; // e.g., 3rd, 23rd
      default:
        return "th"; // e.g., 4th, 24th
    }
  }

  /**
   * Converts an array of strings into a map with boolean values.
   * Each key in the map will be prefixed with the provided keyPrefix.
   *
   * @param keys - An array of strings to be used as keys in the map.
   * @param keyPrefix - A string to prefix each key with. Defaults to an empty string.
   * @returns A map where each key from the array is prefixed and mapped to a boolean value of true.
   */
  arrayToMap(keys: string[], keyPrefix: string = ""): StrToBool {
    let obj: StrToBool = {};
    for (let key of keys) {
      obj[keyPrefix + key] = true;
    }
    return obj;
  }
}


// Types -------------------------------------------------------------
type StrToBool = Record<string, boolean>;

type SymbolData = [
  number, // token
  string, // symbol
  number, // tick Size
  number, // lot size
  string | undefined, // expiry/fullname
  string | undefined, // ISIN
  boolean, // isWeekly
  string // segment2
];

type EquityInstrument = [
  number, // token delta
  string, // ticker
  string, // full name
  number, // tick size
  number, // lot size
  string // ISIN
];

type IndexInstrument = [
  string, // exchange for Index
  EquityInstrument[]
];

type OptionsInstrument = [
  string, // ticker
  number, // tick size
  number, // lot size
  [
    string, // Expiry YYMDD
    Record<
      string,
      [
        number, // token delta
        string | number, // strike price
        number | undefined // lot size
      ][]
    >
  ][]
];

type FuturesInstrument = [
  string, // ticker
  number, // tick size
  number, // Lot size
  [
    number, // token delta
    string, // Expiry
    number | undefined // lot size
  ][]
];

type MakeInstrumentsParams = {
  exchangeToken: number;
  tradingSymbol: string;
  segment: string;
  exchange: string;
  segment2: string;
  tickSize: number;
  lotSize: number;
  company: string;
  isin: string;
  isWeekly: boolean;
  ignoreRelated?: any;
};

type Instrument =
  | IndexInstrument
  | EquityInstrument
  | OptionsInstrument
  | FuturesInstrument;

type DetailedInstrument = {
  scripCode: string;
  type: string;
  expiryDay: number;
  expiryMonth: number;
  expiryYear: number;
  tradingSymbol: string;
  symbol: string;
  segment: string;
  exchange: string;
  segment2: string;
  tickSize: number;
  lotSize: number;
  company: string;
  tradable: boolean;
  precision: number;
  fullName: string;
  niceName: string;
  stockWidget: boolean;
  exchangeToken: number;
  instrumentToken: number;
  isin: string;
  related: DetailedInstrument[];
  underlying?: DetailedInstrument;
  auctionNumber?: number;
  isWeekly: boolean;
  expiryWeek: number;
  isEvent: boolean;
  isFound: boolean;
  strike: number;
  optionType: string;
};

// export default Search;

const SearchInstance = new Search();
SearchInstance.init(<any>instrumentsData);

export default SearchInstance;
