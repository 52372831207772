import {
  Box, createStyles, Text, useMantineTheme,
} from "@mantine/core";
import { memo } from "react";
import { useSelector } from "react-redux";
import {
  ChevronDown, ChevronUp, Circle,
} from "tabler-icons-react";
import { selectQuoteSocketsSelect } from "@redux/sagas/sockets/socketSelectors";
import { getTypographyStyles } from "@theme/themeConfig";
import { getTernaryValue, instrumentSearch } from "@utils/index";
import { Flex } from '@uikit';
import { RootState } from "@redux/store";
import { WATCHLIST_DISPLAY_FORMATS } from "@constants/watchlist";
import FormattedName from "./formattedName";
import type { ISocketWatchlist } from './index.d';

interface WatchlistItemProps {
  symbol: string;
  token: number;
  exchange: string;
  segment: string;
}

const useStyles = createStyles((theme) => ({
  // greenColor: {
  //   color: theme.colorScheme === "dark" ? theme.colors.colorSuccess[0] : theme.colors.colorSuccess[1],
  // },
  // redColor: {
  //   color: theme.colorScheme === "dark" ? theme.colors.colorDanger[0] : theme.colors.colorDanger[1],
  // },
  symbol: {
    // fontSize: "0.8125rem",
    ...getTypographyStyles("content_xs_medium"),
    marginRight: 5,
  },
  ticker: {
    // fontSize: "0.8125rem",
    ...getTypographyStyles("content_xs_regular"),
    alignItems: "center",
    display: "flex",
  },
  ltpPrice: {
    fontWeight: 500,
    minWidth: 68,
    textAlign: "right",
    fontVariantNumeric: "tabular-nums",
  },
  iconContainer: {
    width: 18,
    height: 20,
  },
  icon: {
    marginLeft: 5,
    width: 18,
  },
  neutralIcon: {
    marginLeft: 5,
    width: 18,
    marginTop: 3,
  },
  loss: {
    color: theme.colors.colorDanger,
    marginLeft: 5,
  },
  exchange: {
    fontSize: 7,
    color: "#444",
  },
  dayChange: {
    marginRight: 2,
    minWidth: 45,
    fontVariantNumeric: "tabular-nums",
    display: 'inline-block',
    textAlign: "right",
  },
}));

const WatchlistItem = ({
  symbol, token, exchange, segment,
}: WatchlistItemProps) => {
  const { classes, cx } = useStyles();
  const theme = useMantineTheme();
  const socketWatchlist : ISocketWatchlist = useSelector((state:RootState) => selectQuoteSocketsSelect(state, token));
  const displayFormat = useSelector((state:RootState) => state.watchlist.displayFormat);
  const { ltp, close } = socketWatchlist || { ltp: 0, close: 0 };
  const netChange = socketWatchlist?.netChange || 0;
  const absoluteChange = ((ltp - close) / 100);
  // todo add types
  const parsedSymbolObj: any = instrumentSearch.get(symbol, segment, exchange)

  return (
    <Box
      data-testid="watchlistItem"
      sx={{
        display: "flex",
        // flexDirection: parsedSymbolObj.niceName?.length > 26 ? "column" : 'row',
        justifyContent: "space-between",
      }}
    >
      <Flex>
        <Text
          className={cx(
            classes.symbol,
            getTernaryValue(
              [netChange === 0, netChange > 0],
              ["text_fg_default", "text_success_default", "text_error_default"]
            )
          )}
        >
          <FormattedName
            name={parsedSymbolObj.niceName}
            expiryDay={parsedSymbolObj.expiryDay}
            isWeekly={parsedSymbolObj.isWeekly}
            symbol={parsedSymbolObj.symbol}
            size="xsm"
            isWatchlist
          />
        </Text>
        {exchange === "BSE" ? (
          <span className={classes.exchange}>BSE</span>
        ) : <> </>}
      </Flex>
      {socketWatchlist ? (
        <Text className={classes.ticker} size="sm">
          {displayFormat !== WATCHLIST_DISPLAY_FORMATS[0].value && (
          <Text mr={2} className={displayFormat === "percentageAndAbsolute" ? "text_fg_muted" : ""}>
            {absoluteChange.toFixed(2)}
          </Text>
          )}

          {displayFormat !== WATCHLIST_DISPLAY_FORMATS[1].value && (
          <Text className={classes.dayChange} component="span">
            {netChange?.toFixed(2)}
            <Text sx={{ fontSize: 10 }} component="span">%</Text>
          </Text>
          )}

          <Text component="span" className={classes.iconContainer}>
            {getTernaryValue(
              [netChange === 0, netChange > 0],
              [
                <Circle
                  className={cx(classes.neutralIcon, 'color-grey-1')}
                  size={14}
                />,
                <ChevronUp
                  color={theme.colors.colorSuccess[0]}
                  className={classes.icon}
                  size={20}
                />,
                <ChevronDown
                  color={theme.colors.colorDanger[0]}
                  className={classes.icon}
                  size={20}
                />,
              ]
            )}
          </Text>

          <Text
            className={cx(
              classes.ltpPrice,
              getTernaryValue(
                [netChange === 0, netChange > 0],
                ["text_fg_default", "text_success_default", "text_error_default"]
              )
            )}
            component="span"
          >
            {(Number(socketWatchlist?.ltp) / 100 || 0)?.toFixed(2)}
          </Text>
        </Text>
      ) : null}
    </Box>
  );
};

export default memo(WatchlistItem);
