import {
  Box, Group, NumberInput, Radio, Tabs, Text,
} from "@mantine/core"
import {
  memo, useEffect, useState,
} from "react"
import { useDispatch, useSelector } from "react-redux"
import { ChevronDown, ChevronUp } from "tabler-icons-react"
import { useFocusTrap } from '@mantine/hooks';
import { RootState } from "src/redux/store";
import useOrderWL from "@hooks/common/useOrderWL"
import {
  processOrderInitialState, setProceedOrder, setTradeModalInfo, clearOrderMargin,
} from "@redux/sagas/trades/tradesSlice"
import { convertToTitleCase } from "@utils/string";
import { parsedSymbol } from "@utils/index";
import { useFetchFreezeQty, useFetchTradeMargin } from "@hooks/common";
import useTradeMarginWL from "@hooks/common/useTradeWL";
import { selectUserPreferences } from "@redux/sagas/users/userSelectors";
import useGetSocketVal from "@hooks/common/useGetSocketVal";
import {
  exchanges, OrderTypeML, OrderTypeSLM, productOptions, ValidityRetention, varietyValues,
  orderTypesDisplay, productOptionsDisplay,
} from "../../../utils/constant"
import CustomDraggable from "../../customDraggable"
import useStyles from "../styled"
import {
  handlePriceState,
  handleTriggerPriceError,
  handleTriggerPriceState,
} from "../utils"
import GttTradePart from "./gttTrade"
import TradeModalFooter from "./tradeFooter"
import TraderHeader from "./tradeHeader"
import ValidityOptions from "./validityOptions"
import ProductTypes from "./productTypes";
import BracketOrderInputs from "./bracketOrderInputs";
import { IBracketInputValues, IOrderPreferences } from "./types";
import { getTabValue, roundOffQty, validateBracketOrderValues } from "./helpers";
import QuantityInput from "./components/quantityInput";

const TradeContent = () => {
  const { cx, classes } = useStyles()
  const dispatch = useDispatch();

  const {
    processOrderInfo: { processOrder, selectedToken },
    tradeModalInfo: {
      currentAssetItem,
      buySellToggle,
      isOpenOrder,
      editOrderId = "",
    },
  } = useSelector((state: RootState) => state.trade);
  const {
    data: preferencesData,
  } = useSelector(selectUserPreferences)
  const [productValue, setProductValue] = useState(() => (currentAssetItem.exchange === exchanges.NFO
    ? productOptionsDisplay.OVERNIGHT : productOptionsDisplay.INTRADAY))
  const [isFirstOrder, setIsFirstOrder] = useState<Boolean | null>(true);
  const [orderTypeMLVal, setOrderTypeMLVal] = useState("")
  const [activeTab, setActiveTab] = useState(productOptions[0].value);
  const [showValidityOption, setShowValidityOption] = useState<Boolean | null>(null)
  const [validityVal, setValidityVal] = useState(ValidityRetention.DAY)
  // const [heightTop, setHeightTop] = useState(0)
  const [disclosedQty, setDisclosedQty] = useState(0)
  const [priceField, setPriceField] = useState(currentAssetItem?.price || currentAssetItem.ltp)
  const [triggerPriceField, setTriggerPriceField] = useState(Number(currentAssetItem?.orderTriggerPrice)
    || currentAssetItem.ltp)
  const [selectedExchange, setSelectedExchange] = useState(exchanges.NSE)

  const [stoplossState, setStoplossState] = useState(false)
  const [targetState, setTargetState] = useState(false)
  const [quantity, setQuantity] = useState<number>(Math.abs(currentAssetItem.quantity) || 1)
  const [focusTrapFlag, setFocusTrapFlag] = useState(false)

  const [bracketInputValues, setBracketInputValues] = useState<IBracketInputValues>({
    stopLossPrice: Number(currentAssetItem?.bookLossPrice) || 10,
    profitPrice: Number(currentAssetItem?.bookProfitPrice) || 0,
    trailingSL: Number(currentAssetItem?.trailingPrice) || 0,
  })
  const [error, setError] = useState([
    {
      name: "stopLossPrice",
      error: "",
    },
    {
      name: "profitPrice",
      error: "",
    },
    {
      name: "trailingSL",
      error: "",
    },
  ])
  const { ltp: socketLtp } = useGetSocketVal(currentAssetItem?.token || 0)

  const [ltp, setLtp] = useState(0)

  const focusTrapRef = useFocusTrap();

  useEffect(() => {
    if (currentAssetItem.quantity) {
      const tradeQuantity = currentAssetItem.quantity;
      setQuantity(Math.abs(tradeQuantity));
    } else {
      const instrumentData = parsedSymbol(currentAssetItem.symbol, currentAssetItem.exchange, currentAssetItem.token)
      const defaultQty = instrumentData?.lotSize || 1
      setQuantity(defaultQty)
    }
  }, [currentAssetItem])

  const { handleOrder, handlePriceVal, handleTriggerPriceVal } = useOrderWL({
    orderTypeMLVal,
    priceField,
    activeTab,
    triggerPriceField,
    bracketInputValues,
    qty: quantity,
  })

  useTradeMarginWL()

  useFetchFreezeQty(currentAssetItem, quantity, priceField, orderTypeMLVal, productValue)

  const { marginUsed, charge, handleFetchTradeMargin } = useFetchTradeMargin({
    selectedExchange: currentAssetItem.exchange || selectedExchange,
    token: currentAssetItem.token,
    quantity,
    priceField,
    buySellToggle,
    orderTypeMLVal,
    productValue,
    activeTab,
    triggerPriceField,
    bracketInputValues,
    tradingSymbol: currentAssetItem.tradingSymbol,
  })

  const orderPreferences: IOrderPreferences = JSON.parse(localStorage.getItem("orderPreferences") || "{}")
  const {
    isValidityOption, orderType, product, variety, fnoProductType,
  } = orderPreferences

  useEffect(() => {
    if (currentAssetItem.orderType || currentAssetItem.product) {
      const { orderType: orderTypeVal, product: productVal } = currentAssetItem;
      setShowValidityOption(isValidityOption);
      setOrderTypeMLVal(orderTypeVal || OrderTypeML[0].value);
      setProductValue(productVal);
      setActiveTab(getTabValue(productVal));
    } else if (orderType && product && variety) {
      setShowValidityOption(isValidityOption);
      setOrderTypeMLVal(orderType);
      setActiveTab(variety);
      if (currentAssetItem.exchange === exchanges.NFO || currentAssetItem.exchange === exchanges.BFO) {
        setProductValue(fnoProductType)
      } else {
        setProductValue(product);
      }
    } else {
      localStorage.setItem(
        "orderPreferences",
        JSON.stringify({
          isValidityOption: false,
          orderType: OrderTypeML[0].value,
          product: productOptions[1].value,
          variety: varietyValues.REGULAR,
          fnoProductType: productOptions[2].value,
        })
      );
      setShowValidityOption(false);
      setOrderTypeMLVal(OrderTypeML[0].value);
      setActiveTab(varietyValues.REGULAR);
      if (currentAssetItem.exchange === "NFO" || currentAssetItem.exchange === exchanges.BFO) {
        setProductValue(productOptions[2].value)
      } else {
        setProductValue(productOptions[1].value);
      }
    }
  }, [currentAssetItem]);

  const handleProductValue = (val: string) => {
    setProductValue(val)
    if (currentAssetItem.exchange === exchanges.NFO || currentAssetItem.exchange === exchanges.BFO) {
      localStorage.setItem("orderPreferences", JSON.stringify({
        ...orderPreferences,
        fnoProductType: val,
      }))
    } else {
      localStorage.setItem("orderPreferences", JSON.stringify({
        ...orderPreferences,
        product: val,
      }))
    }
  }

  // if product is cover and the modal is opened from any table
  useEffect(() => {
    // if the modal is opened from any table so the currentAssetItem will have some values
    if (Object.keys(currentAssetItem).length > 0) {
      if ((exchanges.NFO === currentAssetItem.exchange
        && (product === productOptionsDisplay.COVER || variety === varietyValues.COVER)
      )) {
        handleProductValue(productOptionsDisplay.OVERNIGHT);
        localStorage.setItem(
          "orderPreferences",
          JSON.stringify({
            ...orderPreferences,
            variety: varietyValues.REGULAR,
          })
        );
        setActiveTab(varietyValues.REGULAR);
      } else if (
        (currentAssetItem.exchange === exchanges.NSE || currentAssetItem.exchange === exchanges.BSE)
        && product === productOptionsDisplay.OVERNIGHT
      ) {
        handleProductValue(productOptionsDisplay.INTRADAY);
      }
    }
  }, [currentAssetItem])

  const handleOrderType = (val: string) => {
    setOrderTypeMLVal(val)
    localStorage.setItem("orderPreferences", JSON.stringify({
      ...orderPreferences,
      orderType: val,
    }))
  }

  const handleTabChange = (tab: string) => {
    localStorage.setItem("orderPreferences", JSON.stringify({
      ...orderPreferences,
      variety: tab,
      orderType: OrderTypeML[0].value,
      product: productOptions[0].value,
    }))
    setActiveTab(tab)
    if (productValue === productOptionsDisplay.COVER) {
      setProductValue(productOptionsDisplay.INTRADAY);
    }
  }

  const handleResetTradeModalInfo = () => {
    dispatch(clearOrderMargin())
    dispatch(setProceedOrder(processOrderInitialState));
  }

  const handleCancelTrade = () => {
    dispatch(
      setTradeModalInfo({
        buySellToggle: false,
        showTradeModal: false,
        // @ts-ignore
        currentAssetItem: {},
      })
    );
    handleResetTradeModalInfo()
  }

  const handleProceedOrder = (token: number) => {
    // validation for bracket order
    const isOrderAllowed = preferencesData.stickyOrderWindow || isFirstOrder
    if (isOrderAllowed) {
      const validatedBracketOrderValues = validateBracketOrderValues(bracketInputValues)
      if (productValue === productOptionsDisplay.BRACKET && validatedBracketOrderValues !== "") {
        setError((prevState) => prevState.map((item) => {
          if (item.name === validatedBracketOrderValues) {
            return {
              ...item,
              error: `${convertToTitleCase(item.name)} cannot be 0`,
            }
          }
          return item
        }))
      } else {
        handleOrder({
          token: token || currentAssetItem.token,
          selectedExchange: currentAssetItem.exchange || selectedExchange,
          quantity,
          disclosedQty,
          productValue,
          buySellToggle,
          orderTypeMLValue: orderTypeMLVal,
          validityVal,
          symbol: currentAssetItem.tradingSymbol,
        });
      }
    }
    setIsFirstOrder(false);
  }

  useEffect(() => {
    if (selectedToken !== 0) {
      handleProceedOrder(selectedToken)
    }
    dispatch(setProceedOrder(processOrderInitialState));
  }, [processOrder])

  const lotsize = currentAssetItem.lotSize

  const currentAssetLtp = currentAssetItem.price || currentAssetItem.ltp || ltp
  // if edit order id is not there then set the price field to the price order was placed not ltp
  useEffect(() => {
    if (!editOrderId) {
      setPriceField(currentAssetLtp)
    }
  }, [currentAssetLtp])

  useEffect(() => {
    if (!editOrderId) {
      setPriceField(socketLtp)
    }
  }, [productValue, orderTypeMLVal])

  useEffect(() => {
    setTriggerPriceField(Number(currentAssetItem?.orderTriggerPrice) || currentAssetLtp)
  }, [currentAssetItem])

  const handleValidityOption = () => {
    setShowValidityOption(!showValidityOption)
    localStorage.setItem("orderPreferences", JSON.stringify({
      ...orderPreferences,
      isValidityOption: !showValidityOption,
    }))
    setValidityVal(ValidityRetention.DAY)
  }

  // handle values on tab change
  useEffect(() => {
    if (activeTab === varietyValues.COVER
      && (currentAssetItem.exchange === exchanges.NSE || currentAssetItem.exchange === exchanges.BSE)) {
      setProductValue(productOptionsDisplay.COVER)
      setValidityVal(ValidityRetention.DAY);
      if (orderTypeMLVal !== orderTypesDisplay.MARKET && orderTypeMLVal !== orderTypesDisplay.LIMIT) {
        setOrderTypeMLVal(OrderTypeML[0].value);
      }
    } else if (activeTab === varietyValues.BRACKET) {
      handleProductValue(productOptionsDisplay.BRACKET);
      if (orderTypeMLVal !== orderTypesDisplay.STOPLOSSLIMIT && orderTypeMLVal !== orderTypesDisplay.LIMIT) {
        setOrderTypeMLVal(OrderTypeML[1].value);
      }
    } else if (activeTab === varietyValues.REGULAR) {
      if (productValue !== productOptionsDisplay.INTRADAY && productValue !== productOptionsDisplay.LONGTERM) {
        if (currentAssetItem.exchange === exchanges.NSE || currentAssetItem.exchange === exchanges.BSE) {
          handleProductValue(productOptionsDisplay.INTRADAY);
        } else if (currentAssetItem.exchange === exchanges.NFO || currentAssetItem.exchange === exchanges.BFO) {
          handleProductValue(productOptionsDisplay.OVERNIGHT);
        }
      }
    } else if (activeTab === varietyValues.AMO) {
      if (productValue !== productOptionsDisplay.INTRADAY && productValue !== productOptionsDisplay.LONGTERM) {
        handleProductValue(productOptionsDisplay.INTRADAY);
      }
    }
  }, [activeTab])

  const handleChangeQty = (val: number, lotSizeVal: number) => {
    setQuantity(roundOffQty(val, lotSizeVal))
  }

  // we were initially setting focus to the qty input filed by using focusTrapRef
  // but it was causing the input field to get incorrect value only in safari
  // so the below code delay the focus trap until after initial render and state update
  useEffect(() => {
    const timer = setTimeout(() => {
      setFocusTrapFlag(true);
    }, 0);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => () => {
    handleResetTradeModalInfo()
    setIsFirstOrder(true);
  }, [])

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleProceedOrder(currentAssetItem.token);
    }
  }

  return (
    <CustomDraggable
      buySellToggle={buySellToggle}
      headerContent={(
        <TraderHeader
          selectedExchange={selectedExchange}
          setSelectedExchange={setSelectedExchange}
          currentAssetItem={currentAssetItem}
          quantity={quantity}
          setLtp={setLtp}
          ltpVal={ltp}
        />
      )}
    // manageTop={heightTop}
    >
      <Box
        onKeyDown={handleKeyDown}
        ref={focusTrapFlag ? focusTrapRef : null}
        className={classes.tradeContentContainer}
      >
        <Tabs
          classNames={{
            tabLabel: classes.tabLabel,
            tabsList: classes.tabsList,
          }}
          color="blue"
          value={activeTab}
          onTabChange={handleTabChange}
        >
          <Tabs.List>
            <Tabs.Tab disabled={isOpenOrder} value={varietyValues.REGULAR}>
              Regular
            </Tabs.Tab>
            {(exchanges.NSE === currentAssetItem.exchange || exchanges.BSE === currentAssetItem.exchange) && (
              <Tabs.Tab disabled={isOpenOrder} value={varietyValues.COVER}>
                Cover
              </Tabs.Tab>
            )}
            <Tabs.Tab disabled={isOpenOrder} value={varietyValues.BRACKET}>
              Bracket
            </Tabs.Tab>
            <Tabs.Tab disabled={isOpenOrder} value={varietyValues.AMO}>
              AMO
            </Tabs.Tab>
          </Tabs.List>
          <Tabs.Panel value={varietyValues.REGULAR}> </Tabs.Panel>
          <Tabs.Panel value={varietyValues.COVER}> </Tabs.Panel>
          <Tabs.Panel value={varietyValues.BRACKET}> </Tabs.Panel>
          <Tabs.Panel value={varietyValues.AMO}> </Tabs.Panel>

          <Box className={classes.tradeContentInnerContainer}>
            <ProductTypes
              productValue={productValue}
              handleProductValue={handleProductValue}
              buySellToggle={buySellToggle}
              activeTab={activeTab}
              exchange={currentAssetItem.exchange}
              isOpenOrder={isOpenOrder}
            />
            {/* main content qty, price, trigger price */}
            <Box className={cx("trade-content", classes.tradeItemContainer)}>
              <QuantityInput
                lotsize={lotsize || 1}
                initialQuantity={quantity}
                onQuantityChange={handleChangeQty}
                price={priceField}
              />
              <Box className={classes.tradeItem}>
                <NumberInput
                  type="number"
                  mt="xl"
                  id="trade-price"
                  placeholder="Price"
                  description="Price"
                  precision={2}
                  step={0.05}
                  min={0.05}
                  value={handlePriceVal()}
                  onChange={(val: number) => setPriceField(val)}
                  disabled={handlePriceState(orderTypeMLVal)}
                  classNames={{
                    control: classes.numberInputControl,
                    rightSection: classes.numberInputRightSection,
                  }}
                />
                <Radio.Group
                  label=""
                  size="xs"
                  value={orderTypeMLVal}
                  onChange={handleOrderType}
                >
                  <Group mt="xs">
                    {OrderTypeML.map((item) => (
                      <Radio
                        key={item.value}
                        value={item.value}
                        label={item.label}
                        color={buySellToggle ? "red" : "blue"}
                        disabled={item.disabled.includes(activeTab)}
                        tabIndex={orderTypeMLVal === item.value ? 0 : -1}
                      />
                    ))}
                  </Group>
                </Radio.Group>
              </Box>
              <Box className={classes.tradeItem}>
                <NumberInput
                  type="number"
                  mt="xl"
                  id="trigger-price"
                  placeholder={
                    activeTab === varietyValues.COVER
                      ? "Stoploss Trigger Price"
                      : "Trigger Price"
                  }
                  description={
                    activeTab === varietyValues.COVER
                      ? "Stoploss Trigger Price"
                      : "Trigger Price"
                  }
                  precision={2}
                  step={0.05}
                  min={0.05}
                  disabled={handleTriggerPriceState(activeTab, orderTypeMLVal)}
                  value={handleTriggerPriceVal()}
                  onChange={(val: number) => setTriggerPriceField(val)}
                  error={handleTriggerPriceError(
                    triggerPriceField,
                    priceField,
                    orderTypeMLVal,
                    buySellToggle
                  )}
                  classNames={{
                    control: classes.numberInputControl,
                    rightSection: classes.numberInputRightSection,
                  }}
                />
                <div className="orderTypeSLM">
                  <Radio.Group
                    value={orderTypeMLVal}
                    onChange={handleOrderType}
                    label=""
                    size="xs"
                  >
                    <Group mt="xs">
                      {OrderTypeSLM.map((item) => (
                        <Radio
                          key={item.value}
                          value={item.value}
                          label={item.label}
                          color={buySellToggle ? "red" : "blue"}
                          disabled={item.disabled.includes(activeTab)}
                          tabIndex={orderTypeMLVal === item.value ? 0 : -1}
                        />
                      ))}
                    </Group>
                  </Radio.Group>
                </div>
              </Box>
            </Box>
            {/* Extra values for Bracket orders */}
            {activeTab === varietyValues.BRACKET && (
              <BracketOrderInputs
                bracketInputValues={bracketInputValues}
                setBracketInputValues={setBracketInputValues}
                error={error}
                setError={setError}
              />
            )}
            <Text
              className={classes.validityOptionText}
              color="blue"
              size="sm"
              onClick={handleValidityOption}
            >
              {showValidityOption ? (
                <>
                  Hide options
                  <ChevronUp />
                </>
              ) : (
                <>
                  More options
                  <ChevronDown />
                </>
              )}
            </Text>

            {showValidityOption ? (
              <ValidityOptions
                activeTab={activeTab}
                validityVal={validityVal}
                setValidityVal={setValidityVal}
                disclosedQty={disclosedQty}
                setDisclosedQty={setDisclosedQty}
                isOpenOrder={isOpenOrder}
              />
            ) : null}

            <GttTradePart
              productValue={productValue}
              activeTab={activeTab}
              stoplossState={stoplossState}
              setStoplossState={setStoplossState}
              targetState={targetState}
              setTargetState={setTargetState}
            />
          </Box>
        </Tabs>
        <TradeModalFooter
          marginUsed={marginUsed}
          totalCharge={charge.total || 0}
          buySellToggle={buySellToggle}
          handleCancelTrade={handleCancelTrade}
          currentToken={currentAssetItem.token}
          triggerPriceError={handleTriggerPriceError(
            triggerPriceField,
            priceField,
            orderTypeMLVal,
            buySellToggle
          )}
          handleProceedOrder={handleProceedOrder}
          handleOrderMargin={handleFetchTradeMargin}
        />
      </Box>
    </CustomDraggable>
  );
}

export default memo(TradeContent)
