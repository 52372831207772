import { Radio, Group } from "@mantine/core";
import {
  exchanges,
  productOptions,
  varietyValues,
} from "@utils/constant";

interface ProductTypesProps {
  productValue: string;
  handleProductValue: (value: string) => void;
  buySellToggle: boolean;
  activeTab: string;
  exchange: string;
  isOpenOrder: boolean | undefined;
}

const separateText = (text: string) => {
  const textValue = text.split(" ");
  return (
    <p>
      {textValue[0]}
      {' '}
      <span className="text_fg_muted">{textValue[1]}</span>
      {" "}
    </p>
  );
};

const ProductTypes = ({
  productValue,
  handleProductValue,
  buySellToggle,
  activeTab,
  exchange,
  isOpenOrder,
}: ProductTypesProps) => {
  const renderRadio = (value: string, label: string, disabled : boolean | undefined = false) => (
    <Radio
      value={value}
      label={separateText(label)}
      color={buySellToggle ? "red" : "blue"}
      disabled={disabled}
    />
  );

  return (
    <Radio.Group
      label=""
      size="xs"
      value={productValue}
      onChange={handleProductValue}
    >
      <Group>
        {(activeTab === varietyValues.REGULAR || activeTab === varietyValues.AMO)
         && (exchange === exchanges.NSE || exchange === exchanges.BSE) && (
         <>
           {renderRadio(productOptions[0].value, productOptions[0].label, isOpenOrder)}
           {renderRadio(productOptions[1].value, productOptions[1].label, isOpenOrder)}
         </>
        ) }

        {(activeTab === varietyValues.REGULAR || activeTab === varietyValues.AMO)
        && (exchange === exchanges.NFO || exchange === exchanges.BFO) && (
          <>
            {renderRadio(productOptions[0].value, productOptions[0].label, isOpenOrder)}
            {renderRadio(productOptions[2].value, productOptions[2].label, isOpenOrder)}
          </>
        )}

        {activeTab === varietyValues.BRACKET && (
          <>
            {renderRadio(productOptions[4].value, productOptions[4].label, true)}
          </>
        )}

        {activeTab === varietyValues.COVER && (
          <>
            {renderRadio(productOptions[3].value, productOptions[3].label, true)}
          </>
        )}

      </Group>
    </Radio.Group>
  );
}

export default ProductTypes;
